import OneLinkUrlGenerator from "@lib/oneLinkUrlGenerator";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function useOneLinkUrl(
  initialUrl: string,
  allowDeepLink?: boolean
) {
  const { asPath, locale } = useRouter();
  const [url, setUrl] = useState(initialUrl);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const oneLinkGenerator = new OneLinkUrlGenerator({
        oneLinkURL: initialUrl,
        pidKeysList: ["af_pid", "utm_source"],
        campaignKeysList: ["af_c", "utm_campaign"],
        skipList: [],
      });

      oneLinkGenerator.setDeepLinkValue("deep_link_value", asPath);
      oneLinkGenerator.setAfSub1("url", asPath);
      oneLinkGenerator.setAdset("language", locale);

      setUrl(oneLinkGenerator.generateUrl(allowDeepLink));
    }
  }, [initialUrl, asPath, locale]);

  return url;
}
